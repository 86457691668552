import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { Link } from 'gatsby';
import styles from './Feed.module.scss';

const Feed = ({ edges }) => (
  <div className={styles['feed']}>
    {edges.map((edge) => (
      <div className={styles['feed__item']} key={edge.node.fields.slug}>
        <div className={styles['feed__item-meta']}>
          <time className={styles['feed__item-meta-time']} style={edge.node.frontmatter.date ? {} : { display: 'none' }} dateTime={moment(edge.node.frontmatter.date).format('MMMM D, YYYY')}>
            {moment(edge.node.frontmatter.date).format('MMMM YYYY')}
          </time>
          <time className={styles['feed__item-meta-time']} style={(edge.node.frontmatter.dateFrom && edge.node.frontmatter.dateTo) ? {} : { display: 'none' }} dateTime={moment(edge.node.frontmatter.dateFrom).format('MMMM D, YYYY')}> 
              {moment(edge.node.frontmatter.dateFrom).format('MMMM YYYY')} - {moment(edge.node.frontmatter.dateTo).format('MMMM YYYY')}
          </time>
          <time className={styles['feed__item-meta-time']} style={(edge.node.frontmatter.dateFrom && !edge.node.frontmatter.dateTo) ? {} : { display: 'none' }} dateTime={moment(edge.node.frontmatter.dateFrom).format('MMMM D, YYYY')}> 
              {moment(edge.node.frontmatter.dateFrom).format('MMMM YYYY')} à ce jour
          </time>
          <span className={styles['feed__item-meta-divider']} />
          <span className={styles['feed__item-meta-category']}>
            <Link to={edge.node.fields.categorySlug} className={styles['feed__item-meta-category-link']}>{edge.node.frontmatter.category}</Link>
          </span>
        </div>
        <h2 className={styles['feed__item-title']}>
          <Link className={styles['feed__item-title-link']} to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
        </h2>
        <p className={styles['feed__item-description']}>{edge.node.frontmatter.description}</p>
        <Link className={styles['feed__item-readmore']} to={edge.node.fields.slug}>Voir le détail</Link>
      </div>
    ))}
  </div>
);

export default Feed;
